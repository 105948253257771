:root {
    --input-box-color: #FAFBF8;
    --main-green-on-the-site: #7BC12E;

    --Medium: 4px;
}

input,
textarea,
select {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

body {
    color: #000000;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 1.3;
    cursor: default
}

li {
    list-style-type: none;
}

ul {
    padding: 0;
    margin: 0;
}

.container_fluid {
    width: 100%;
}

.container {
    width: 100%;
    margin: 0 auto;
}

img {
    max-width: 100%;
}


/* Header */
.header_fix {
    max-width: 100%;
    width: 100%;
}

.header_section {
    width: 100%;
    display: flex;
    padding: 14px 0;
    margin-top: 5px;
    justify-content: space-between;
}

.right_header {
    display: flex;
}

.right_header .logo {
    margin-top: -9px;
}

.right_header .logo img {
    max-height: 34px;
}

.navigation {
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 0 30px;
    white-space: nowrap;
}

.navigation>ul>li {
    list-style-type: none;
    display: block;
    float: left;
    position: relative;
    padding: 0 11.5px;
}

.navigation>ul>li>a {
    color: #161616;
    padding: 7px 0;
    text-decoration: none;
    position: relative;
    display: block;
    border-radius: 2px
}

.navigation>ul>li:hover>a {
    color: rgba(123, 193, 46, 1);
    font-weight: 400;
}

.navigation>ul>li>a.active {
    color: #7bc12e;
    font-weight: 700;
}

.navigation>ul>li>ul {
    display: none;
    position: absolute;
    background-color: rgba(245, 249, 232, 1);
    left: 21px;
    top: 36px;
    z-index: 99900;
    margin: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    border-top: rgba(110, 176, 36, 1) 3px solid
}

.navigation>ul>li>ul>li:first-child>a,
.nav_menu>ul>li>ul>li>ul>li:first-child>a,
.navigation>ul>li>ul>li>ul>li>ul>li:first-child>a,
.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li:first-child>a {
    border-top: 0
}

.navigation>ul>li:hover>ul {
    display: block;
    border-radius: 0 0 4px 4px;
}

.navigation>ul>li>ul>li {
    width: 100%;
    float: left;
    position: relative;
    list-style-type: none;
}

.navigation>ul>li>ul>li>ul>li>ul>li>a,
.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li>a {
    font-size: 13px;
    font-weight: 400;
    text-decoration: none
}

.navigation>ul>li>ul>li>a {
    color: rgba(98, 98, 98, 1);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 40px 10px 10px;
    display: block;
    text-decoration: none;
}

.navigation>ul>li>ul>li:last-child>a {
    border-radius: 0 0 4px 4px;
}

.navigation>ul>li>ul>li>a:hover {
    color: #ffffff;
    background-color: rgba(110, 176, 36, 1);
}

.navigation>ul>li>ul>li>ul {
    display: none;
    position: absolute;
    width: 280px;
    background-color: #fff;
    left: 280px;
    top: 0px;
    z-index: 99900;
    margin: 0;
}

.navigation>ul>li>ul>li:hover>ul {
    display: block;
}

.user_menu {
    display: flex;
    padding-bottom: 6px;
}

.user_menu>a {
    display: block;
    padding: 7px 15px;
    border-radius: 4px;
    text-decoration: none;
    color: #000000;
    margin-left: 20px;
}

.user_menu a.get_btn {
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 7px;
}

.user_menu a.get_btn:hover {
    background-color: #6EB024;
    color: #ffffff;
}

.user_menu a.login_btn {
    background-color: #ffffff;
    border: #000000 1px solid;
    color: #000000;
    padding: 7px 30px;
    font-size: 14px;
    margin-bottom: 7px;
}

.user_menu a.login_btn:hover {
    background-color: #6EB024;
    color: #ffffff;
    border-color: #6EB024;
}

.user_menu a img {
    vertical-align: top;
}

.user_menu a.search_icon {
    width: 35px;
    text-align: center;
    height: 35px;
    border-radius: 35px;
    font-size: 26px;
    padding: 0;
    display: inline-block;
    line-height: 30px;
    margin-top: 0;
}

.user_menu a.search_icon:hover {
    color: #6EB024;
    cursor: pointer;
}

.user_menu a:hover {
    color: #F6911E
}

.active_menu {
    overflow: hidden;
}


/* footer */
.searh_outer {
    min-width: 320px;
    padding-bottom: 25px;
}

.social_links {
    padding-top: 24px;
}

.social_links ul {
    display: flex;
    gap: 30px;
}

.social_links ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 30px;
}

.footer_bg {
    background-color: #232326;
    padding: 40px 0 5px;
    margin-top: 50px;
}

.footer_section {
    display: flex;
}

.footer_section .footer_content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.bottom_links {
    width: 50%;
}

.bottom_links h6 {
    color: #7bc12e;
    font-size: 16px;
    padding-bottom: 10px;
    margin: 0;
}

.bottom_links h6.top_btmgap {
    padding-top: 55px;
}

.bottom_links h6 a {
    color: #ffffff;
    text-decoration: none;
}

.bottom_links h6 a:hover {
    text-decoration: underline;
}

.bottom_links ul {
    padding: 0;
    margin: 0;
}

.bottom_links ul li {
    padding-bottom: 10px;
}

.bottom_links ul li a {
    color: #ffffff;
    text-decoration: none;
}

.bottom_links ul li a:hover {
    font-weight: 600;
}

.btm_links_new {
    padding-top: 30px;
}

.btm_links_new h6 {
    color: #7bc12e;
    font-size: 16px;
    padding-bottom: 12px;
    display: inline-block;
    width: 50%;
}

.btm_links_new h6 a {
    color: #ffffff;
    text-decoration: none;
}

.btm_links_new h6 a:hover {
    font-weight: 600;
}


.copyright_bg {
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
}

.copyright_section {
    display: flex;
    justify-content: center;
}

.copyright_section a {
    color: #ffffff;
    text-decoration: none;
}

.copyright_section a:hover {
    font-weight: 600;
}

.copyright_section span {
    padding: 0 5px;
}

.copyright {
    padding-right: 40px;
}

.logo_fix {
    max-width: 600px;
}

.logo_slider {
    width: 100%;
    background-color: rgba(163, 205, 57, 0.25);
    padding: 60px 0;
    margin-top: 70px;
}

.logo_slider .owl-carousel .owl-item {
    text-align: center;
}

.logo_slider .owl-carousel .owl-item img {
    width: inherit;
}

.logo_slider .icon_border {
    width: 100px;
    display: inline-block;
    background-color: #ffffff;
    height: 100px;
    box-shadow: 1.1446px 2.28919px 3.43379px rgba(67, 93, 88, .25);
    border-radius: 5px;
}

.logo_slider .swiper,
.logo_slider swiper-container {
    position: inherit;
}

.logo_fix {
    position: relative;
}

.logo_slider .swiper-button-prev {
    left: -18px;
    color: #7BC12E;
    top: 47px;
}

.logo_slider .swiper-button-next {
    right: -18px;
    color: #7BC12E;
    top: 47px
}

.edge_btns {
    display: flex;
    gap: 10px;
    padding-top: 20px;
}

.edge_btns a {
    color: #7BC12E;
    border: #7BC12E 1px solid;
    padding: 12px;
    display: block;
    background-color: #ffffff;
    border-radius: 4px;
    text-decoration: none;
    flex: 1;
    text-align: center;
    font-weight: 600;
}

.edge_btns a:hover {
    color: #6EB024;
    border: #6EB024 2px solid;
    font-weight: 700;
    padding-top: 11px;
    padding-bottom: 11px;
}

/* .slider_btm{padding: 0 20px;} */

.newsletter {
    border-bottom: #fff 1px solid;
    padding-bottom: 24px;
}


/* support */
.support_slider {
    width: 100%;
    display: flex;
}

.support_slider .support_left {
    flex: 1;
}

.support_slider .support_left h2 {
    font-weight: 700;
    font-size: 36px;
    padding-top: 100px;
    padding-bottom: 20px;
}

.support_slider .support_left p {
    font-size: 20px;
}

.support_slider .support_right {
    flex: 1;
    text-align: center;
}

.support_area {
    width: 100%;
    border: #7bc12e 2px solid;
    margin-top: 20px;
    padding: 50px 60px;
    border-radius: 16px;
    margin: 100px 0 40px;
}

.support_area h4 {
    font-weight: 700;
    font-size: 20px;
}

.support_area p {
    font-weight: 400;
    font-size: 18px;
}

.support_bottom_block {
    display: flex;
    width: 100%;
    gap: 16px;
}

.support_bottom_block .support_btm_area {
    border: rgba(67, 93, 88, 0.25) 2px solid;
    border-radius: 12px;
    padding: 13px 19px;
    text-align: center;
}

.support_bottom_block .support_btm_area .support_img {
    min-height: 176px;
}

.support_bottom_block .support_btm_area h3 {
    font-size: 20px;
    font-weight: 700;
    padding: 20px 0 0;
}

.support_bottom_block .support_btm_area .support_content {
    max-width: 70%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;
    min-height: 150px;
}

.support_bottom_block .support_btm_area .support_content p {
    margin-bottom: 10px;
}

.support_bottom_block .support_btm_area .support_content a {
    text-decoration: none;
    color: #000000;
}

.support_bottom_block .support_btm_area .support_btn {
    max-width: 70%;
    margin: 0 auto;
    padding-top: 10px;
}

.support_bottom_block .support_btm_area .support_btn a {
    color: #7BC12E;
    border: #7BC12E 1px solid;
    padding: 13px 0;
    border-radius: 4px;
    display: block;
    text-decoration: none;
    font-weight: 600;
}

.support_bottom_block .support_btm_area .support_btn a:hover {
    color: #6EB024;
    border-color: #6EB024;
    border-width: 2px;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 12px;
}

/* Individual Pages */

.topic_header {
    background: rgb(0, 103, 102);
    margin-top: 70px;
    background: linear-gradient(79deg, rgba(0, 103, 102, 1) 0%, rgba(89, 205, 57, 1) 100%);
    width: 100%;
    border-radius: 12px 12px 0 0;
    color: #ffffff;
    display: flex;
}

.topic_header .topic_left {
    flex: 2;
    padding: 40px;
}

.topic_header .topic_left h2 {
    font-weight: 700;
}

.topic_header .topic_right {
    flex: 1;
    text-align: center;
}

.topic_header .topic_right ul {
    max-width: 141px;
    margin: 0 auto;
    text-align: left;
    padding-top: 10px;
}

.topic_header .topic_right ul li {
    padding-bottom: 4px;
}

.topic_header .topic_right .topic_level {
    font-size: 24px;
    font-weight: 500;
    line-height: 32.74px;
    color: #000;
    background-color: #ffcf3d;
    border-radius: 0 0 8px 8px;
    padding: 11px 60px;
    display: inline-block;
}

.topic_header p {
    font-size: 18px;
    font-weight: 400;
}

.tab_topic {
    width: 100%;
    border-bottom: #C1DD7A 1px solid;
    padding-top: 10px;
}

.tab_topic ul li {
    padding: 10px;
    min-width: 100px;
    text-align: center;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 400;
}

.tab_topic ul li:hover {
    color: #6EB024;
    font-weight: 600;
}

.tab_topic ul li.selected {
    color: #7bc12e;
    font-weight: 700;
    position: relative;
    font-weight: 700;
}

.tab_topic ul li.selected::before {
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #7bc12e;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.block_overview {
    display: flex;
    padding-top: 20px;
    gap: 90px;
}

.block_overview .left_overview {
    flex: 2;
}

.block_overview .instruct_right {
    flex: 1;
    padding-top: 14px;
}

.block_overview .instruct_right iframe {
    width: 100%;
    height: 300px;
}

.block_overview .left_overview h2 {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0 5px;
}

.block_overview .left_overview h3 {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 0 0;
}

.block_overview .left_overview p {
    color: #494849;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.block_overview .left_overview a {
    color: #000000;
}

.block_overview .left_overview a:hover {
    color: #F6911E
}

.block_overview .left_overview ul {
    padding: 0 20px 20px 20px;
    color: #494849;
}

.block_overview .left_overview ul li {
    padding: 5px 0;
    list-style-type: disc;
}

.handlab_block {
    padding-top: 20px;
}

.handlab_block iframe {
    width: 100%;
}

.small_content {
    font-size: 14px;
    padding: 10px 0;
    max-width: 614px;
}

.small_content a {
    color: #7bc12e;
    text-decoration: none;
}

.small_content a:hover {
    color: #000000;
}

.track_area {
    width: 100%;
    border: #7bc12e 2px solid;
    margin-top: 20px;
    padding: 25px 60px 15px 60px;
    display: flex;
    border-radius: 16px;
}

.track_area .track_left {
    flex: 1;
    padding: 20px 0;
}

.track_area .track_left h3 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
    margin: 0;
}

.track_area .track_right {
    flex: 1;
    padding-top: 50px;
    text-align: right;
    padding-right: 60px;
}

.track_area .track_right a {
    padding: 10px 110px 10px 110px;
    display: inline-block;
    background-color: #7BC12E;
    text-decoration: none;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 600;
}

.track_area .track_right a:hover {
    background-color: #6EB024;
    font-weight: 700;
}

.contact_area {
    width: 100%;
    border: #7bc12e 2px solid;
    margin-top: 20px;
    padding: 65px 60px;
    border-radius: 16px;
}

.contact_area h4 {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 25px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 60px !important;
}


.newsletter {
    border-bottom: #fff 1px solid;
    padding-bottom: 18px;
    min-width: 308px;
    position: relative;
}

.instruct_video {
    background-color: #08124C;
    width: 100%;
    min-height: 208px;
    padding: 10px 20px 20px 10px;
}

.instruct_video h3 {
    font-size: 22px;
    color: #ffffff;
    padding: 15px 10px;
    line-height: 1.4;
}

.instruct_video a {
    display: inline-block;
    padding: 7px 38px;
    background-color: #7BC12E;
    text-decoration: none;
    color: #ffffff;
    border-radius: 6px;
}

.instruct_video a:hover {
    color: #ffffff;
    border-color: #6EB024;
    font-weight: 700;
}

.instruct_link {
    text-align: right;
}

.newsletter {
    border-bottom: #fff 1px solid;
    padding-bottom: 18px;
}


/* hec community */
.hec_slider {
    display: flex;
    gap: 100px;
    padding-top: 60px;
}

.hec_slider .hec_left {
    flex: 2 1;
    padding-top: 30px;
}

.hec_slider .hec_left h2 {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
}

.hec_slider .hec_left h6 {
    font-size: 18px;
    font-weight: 700;
}

.hec_slider .hec_left h6 img {
    vertical-align: top;
    margin: 0 10px 0 0;
}

.hec_slider .hec_left p {
    font-size: 20px;
    padding-top: 10px;
}

.hec_slider .hec_left .btn_hec {
    padding-top: 10px;
}

.hec_slider .hec_left .btn_hec a {
    background-color: #7BC12E;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    padding: 10px 53px;
    display: inline-block;
    font-weight: 600;
}

.hec_slider .hec_left .btn_hec a:hover {
    background-color: #6EB024;
    font-weight: 700;
}

.hec_slider .hec_right {
    flex: 1;
    text-align: right;
}

.hec_support {
    border: #7BC12E 2px solid;
    padding: 35px;
    gap: 70px;
    margin: 103px 0;
    width: 100%;
    border-radius: 16px;
    display: flex;
}

.hec_support .hec_support_left {
    flex: 1;
}

.hec_support .hec_explore {
    flex: 1;
    text-align: center;
    padding-top: 50px;
}

.hec_support .hec_explore a {
    border: #7BC12E 2px solid;
    color: #7BC12E;
    padding: 13px 120px;
    border-radius: 4px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
}

.hec_support .hec_explore a:hover {
    color: #7BC12E;
    border-color: #6EB024;
    font-weight: 700;
}

.hec_support h3 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
}

.hec_support p {
    font-size: 18px;
    margin: 0;
}

/* download */
.download_slider {
    width: 100%;
    display: flex;
    gap: 60px;
}

.download_slider .download_left {
    flex: 1;
}

.download_slider .download_left h2 {
    font-weight: 700;
    font-size: 36px;
    padding-top: 100px;
    padding-bottom: 20px;
}

.download_slider .download_left p {
    font-size: 20px;
}

.download_slider .download_right {
    flex: 1;
}

.edgeengine_logo {
    display: flex;
    width: 100%;
    gap: 200px;
    padding-top: 100px;
}

.edgeengine_logo .edgeengine_block {
    flex: 1;
}

.edgeengine_logo h3 {
    font-size: 24px;
    font-weight: 600;
}

.edgeengine_logo h4 {
    font-size: 20px;
    font-weight: 600;
    color: #7BC12E;
    padding-bottom: 10px;
}

.edgeengine_logo ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    padding-bottom: 40px;
}

.edgeengine_logo ul li {
    width: 18%;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0 rgba(184, 191, 171, 0.5);
    min-height: 200px;
}

.edgeengine_logo ul li .download_ic {
    width: 100%;
    text-align: center;
    display: flex;
    align-self: center;
    justify-content: center;
    position: relative;
}

.edgeengine_logo ul li .download_ic .icon_hover {
    display: none;
    font-size: 15px;
}

.edgeengine_logo ul li .download_ic:hover .icon_hover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 4px;
    border: #6EB024 1px solid;
}

.edgeengine_logo ul li .download_ic .icon_hover a {
    color: #202022;
    text-decoration: underline;
    font-size: 17px;
    display: block;
    padding-top: 15px;
}

.edgeengine_logo ul li .download_ic .icon_hover a:hover {
    color: #6EB024;
    ;
}

.right_edge ul {
    padding-top: 40px;
}

.table_download {
    display: table;
    width: 100%;
    height: 200px;
}

.table_download .tr_download {
    display: table-row;
}

.table_download .tr_download .td_download {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

/* home page */

.home_wrapper {
    display: flex;
    width: 100%;
    max-width: 1248px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.ai_banner {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    display: inline-flex;
    flex-direction: column;
    width: calc(101vw);
    height: 300px;
    left: 0;
    position: relative;
    align-items: center;
    align-content: center;
    /* background: #331772; */
    background: center no-repeat url("../images/697x300-02-1x.png"), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
    background: -webkit-image-set(
            url("../images/697x300-02-1x.png") 1x,
            url("../images/697x300-02-2x.png") 2x,
            url("../images/697x300-02-3x.png") 3x,
            url("../images/697x300-02-4x.png") 4x
                ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
    background: image-set(
        "../images/697x300-02-1x.png" 1x,
        "../images/697x300-02-2x.png" 2x,
        "../images/697x300-02-3x.png" 3x,
        "../images/697x300-02-4x.png" 4x
            ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
    background-repeat: no-repeat;
    background-position-x: center;
}

.ai_link {
    width: 700px;
    text-align: right;
    padding-top: 20px;
}

.ai_link a {
    text-align: right;
    color: white;
    font-size: 13px;
    font-family: Nunito;
    font-weight: 400;
    word-wrap: break-word;
    text-decoration: none;
}

.ai_buttons {
    width: 100%;
    height: 240px;
    justify-content: center;
    align-items: flex-end;
    gap: 20.72px;
    display: inline-flex;
}

.ai_button_wrapper {
    height: 28.57px;
    padding-left: 16.91px;
    padding-right: 16.91px;
    padding-top: 5.28px;
    padding-bottom: 5.28px;
    background: var(--main-green-on-the-site, #7BC12E);
    border-radius: 21.14px;
    justify-content: center;
    align-items: center;
    gap: 5.28px;
    display: flex;
    width: 120px;
    text-decoration: none;
}

.ai_button {
    text-align: center;
    color: var(--input-box-color, #FAFBF8);
    font-size: 13px;
    font-family: Nunito;
    font-weight: 700;
    word-wrap: break-word;
}

.home_banner {
    display: flex;
    padding: 16px 88px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 431px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 38px;
    background: #FAFFF4;
}

.home_banner_copy_box {
    display: flex;
    width: 461px;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
    flex-shrink: 0;
}

.home_banner_copy_box h1 {
    color: #202022;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home_banner_copy_box p {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home_banner_copy_box a.button {
    display: flex;
    width: 217px;
    height: 42px;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    color: var(--input-box-color, #FAFBF8);

    border-radius: 4px;
    border-color: var(--main-green-on-the-site, #7BC12E);
    background: var(--main-green-on-the-site, #7BC12E);
}

.home_banner_copy_box a.button:hover {
    background-color: #6EB024;
    font-weight: 700;
}

.home_banner_asset {
    width: 327px;
    height: 328px;
    flex-shrink: 0;
}

.home_cards {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
}

.home_card1 {
    display: flex;
    /* height: 380px; */
    padding: 84px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 38px;
    border: 1px solid #EFEFEF;
    background: #FFF;
}

.home_card1_copy_box {
    display: flex;
    width: 180px;
    height: 256px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.home_card1_copy_box h1 {
    color: #000;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home_card1_copy_box p {
    align-self: stretch;

    color: #202022;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home_card1_copy_box a.button {
    display: flex;
    height: 32px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-decoration: none;
    color: var(--Text-Color, #202022);
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: var(--Medium, 4px);
    border: 1px solid var(--main-green-on-the-site, #7BC12E);

}

.home_card1_copy_box a.button:hover {
    color: var(--input-box-color, #FAFBF8);
    background-color: #6EB024;
    font-weight: 700;
}

.home_card1_asset {
    width: 118.912px;
    height: 117.421px;
    flex-shrink: 0;
    align-self: center;
}

.home_card2 {
    display: flex;
    padding: 84px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 38px;
    border: 1px solid #EFEFEF;
    background: #FFF;
}

.home_card2_copy_box {
    display: flex;
    width: 180px;
    height: 256px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.home_card2_copy_box h1 {
    color: #000;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home_card2_copy_box p {
    align-self: stretch;

    color: #202022;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home_card2_copy_box a.button {
    display: flex;
    height: 32px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-decoration: none;
    color: var(--Text-Color, #202022);
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: var(--Medium, 4px);
    border: 1px solid var(--main-green-on-the-site, #7BC12E);

}

.home_card2_copy_box a.button:hover {
    color: var(--input-box-color, #FAFBF8);
    background-color: #6EB024;
    font-weight: 700;
}

.home_card2_asset {
    width: 166.337px;
    height: 118.099px;
    flex-shrink: 0;
    align-self: center;
}

.home_card3 {
    display: flex;
    padding: 84px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 38px;
    border: 1px solid #EFEFEF;
    background: #FFF;
}

.home_card3_copy_box {
    display: flex;
    width: 180px;
    height: 256px;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.home_card3_copy_box h1 {
    color: #000;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home_card3_copy_box p {
    color: #202022;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home_card3_copy_box a.button {
    display: flex;
    height: 32px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-decoration: none;
    color: var(--Text-Color, #202022);
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: var(--Medium, 4px);
    border: 1px solid var(--main-green-on-the-site, #7BC12E);

    width: 210px;

}

.home_card3_copy_box a.button:hover {
    color: var(--input-box-color, #FAFBF8);
    background-color: #6EB024;
    font-weight: 700;
}

.home_card3_asset {
    width: 140.292px;
    height: 142px;
    flex-shrink: 0;
    align-self: center;
}

/* download page */

.download_wrapper {
    display: flex;
    width: 100%;
    max-width: 1248px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding-bottom: 260px;
}

.download_banner {
    display: flex;
    width: 100%;
    max-width: 1248px;
    padding: 16px 88px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 431px;
    flex-wrap: wrap;

    border-radius: 38px;
    background: #FAFFF4;
}

.download_banner_copy_box {
    display: flex;
    width: 461px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.download_banner_copy_box h1 {
    color: #202022;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.download_banner_copy_box h3 {
    color: #202022;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download_banner_copy_box p {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.download_banner_copy_box .Select {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.download_banner_copy_box .Select .os-option {
    padding-left: unset;
}

.download_banner_copy_box .Select .os-option-indent {
    padding-left: 30px;
}

.download_banner_copy_box a.button {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    color: var(--input-box-color, #FAFBF8);

    border-radius: 4px;
    border-color: var(--main-green-on-the-site, #7BC12E);
    background: var(--main-green-on-the-site, #7BC12E);
}

.download_banner_copy_box a.button:hover {
    background-color: #6EB024;
    font-weight: 700;
}

.download_banner_copy_box a.button-disabled {
    display: flex;
    height: 42px;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    color: var(--input-box-color, #FAFBF8);

    border-radius: 4px;
    border-color: var(--main-green-on-the-site, #7BC12E);
    background: var(--main-green-on-the-site, #7BC12E);

    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.download_banner_asset {
    width: 327px;
    height: 328px;
    flex-shrink: 0;
}

/* courses page */

.courses_wrapper {
    display: flex;
    width: 100%;
    max-width: 1248px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.courses_wrapper h2 {
    padding-top: 24px;
    padding-bottom: 16px;
    align-self: flex-start;
}

.courses_banner {
    display: flex;
    width: 100%;
    max-width: 1248px;
    padding: 16px 88px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    /* row-gap: 431px; */
    align-self: stretch;
    flex-wrap: wrap;

    border-radius: 38px;
    background: #FAFFF4;
}

.courses_banner_copy_box {
    display: flex;
    max-width: 461px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.courses_banner_copy_box h1 {
    color: var(--Text-Color, #202022);
    font-family: Nunito;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    width: 531px;
}

.courses_banner_copy_box p {
    color: var(--Text-Color, #202022);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    max-width: 625px;
}

.courses_banner_asset {
    width: 441px;
    height: 245px;
    flex-shrink: 0;
    overflow: hidden;

    border-radius: 13px;
}

.courses_videos {
    display: flex;
    width: 100%;
    max-width: 1248px;
    padding-bottom: 88px;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 16px;
}

.courses_video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    overflow: hidden;
    /* The hight is intentionally set relative to viewport width */
    height: 11vw;
    min-height: 200px;
    flex-shrink: 0;

    max-width: 360px;
    min-width: 340px;

    border-radius: 16px;
    background: #FFF;

}

/* youtube embed wrapper */
[data-responsive-youtube-container] {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 1em 0;
}

[data-responsive-youtube-container]>iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.courses_list {
    display: flex;
    width: 100%;
    max-width: 1248px;
    /* padding: 16px 88px; */
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    /* row-gap: 431px; */
    align-self: stretch;
    flex-wrap: wrap;
    gap: 16px;
}

.courses_course {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* flex: 1 0 0; */
    max-width: 360px;
}

.course_card {
    text-decoration: none;
}

.course_card_image {
    display: flex;
    height: 211px;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 20px;
}

.course_card_level {
    display: flex;
    width: 206.756px;
    height: 28px;
    padding: 3.254px 14.099px;
    justify-content: center;
    align-items: center;
    gap: 10.845px;

    position: relative;
    left: 1px;
    top: 45px;

    border-radius: var(--Medium, 4px) 0px;

    color: var(--Text-Color, #202022);
    text-align: center;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.course_card_copy {
    display: flex;
    height: 166px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.course_card_copy p {
    color: var(--Text-Color, #202022);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.course_card_topics {
    color: var(--main-green-on-the-site, #7BC12E);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.course_card_heading {
    color: var(--Text-Color, #202022);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


/* .home_slider{display: flex; padding: 100px 0 130px; justify-content: space-between;} */
.home_slider {
    display: flex;
    padding: 40px 0 40px;
    justify-content: space-between;
}

.home_slider .slider_left {
    width: 56%;
}

.home_slider .slider_left h2 {
    font-size: 40px;
    font-weight: 700;
    padding: 40px 0 0;
}

.home_slider .slider_left p {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.home_slider .slider_left .slide_btns {
    display: flex;
    padding-top: 40px;
}

.home_slider .slider_left .slide_btns span {
    flex: 5;
    font-size: 16px;
    padding-left: 20px;
    line-height: 1.4;
}

.home_slider .slider_left .slide_btns span a {
    color: #202022;
    font-weight: 600;
}

.home_slider .slider_left .slide_btns span a:hover {
    color: #6EB024;
    font-weight: 700;
}

.home_slider .slider_left .slide_btns a.get_started_btn {
    padding: 12px 20px;
    background-color: #7BC12E;
    color: #ffffff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    flex: 2;
    text-align: center;
}

.home_slider .slider_left .slide_btns a.get_started_btn:hover {
    background-color: rgba(110, 176, 36, 1)
}

.home_slider .slide_right {
    text-align: center;
    width: 42%;
}

.home_slider .swiper-slide {
    background-color: #ffffff;
    min-height: 157px;
}

/* .gif_animation{padding-top: 20px;}  */

.journey_area h2 {
    font-size: 32px;
    font-weight: 700;
}

.journey_area p {
    font-size: 20px;
    font-weight: 400;
}

.journey_area .journey_list .journey_block {
    display: flex;
    gap: 20px;
    flex-direction: row-reverse;
    padding-top: 58px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.journey_area .journey_list .journey_block .journey_tp {
    width: 49%;
}

.journey_area .journey_list .journey_block .joruney_outer {
    width: 45%;
}

.journey_area .journey_list .journey_block:nth-child(2n) {
    flex-direction: row
}

.journey_block .journey_tp {
    width: 100%;
}

.journey_block .joruney_outer {
    padding: 60px 0px 20px 0px;
    border-radius: 0 0 6px 6px;
    position: relative;
    top: -50px;
}

.journey_block .joruney_outer h2 {
    font-weight: 600;
    font-size: 32px;
    color: rgba(123, 193, 46, 1);
    padding: 10px 0 0;
}

.journey_block .joruney_outer h3 {
    font-weight: 700;
    font-size: 22px;
    margin: 10px 0 5px;
}

.journey_block .joruney_outer h3 a {
    text-decoration: underline;
    color: #000000;
}

.journey_block .joruney_outer h3 a:hover {
    color: rgba(123, 193, 46, 1);
}

.journey_block .joruney_outer p {
    font-size: 16px;
}

.journey_block .joruney_outer h4 {
    font-weight: 700;
    color: #7BC12E;
    padding: 20px 0 10px;
    clear: both;
}

.journey_block .joruney_outer ul li {
    padding-bottom: 30px;
    padding-left: 30px;
}

.journey_block .joruney_outer ul li a {
    text-decoration: underline;
    color: #000000;
}

.journey_block .joruney_outer ul li a:hover {
    color: rgba(123, 193, 46, 1);
}

.journey_block .joruney_outer .journey_count span {
    font-size: 164px;
    color: rgba(123, 193, 46, 0.4);
    float: left;
    line-height: 1;
    padding-right: 20px;
}

/* Complementry Courses */
.inner_slider {
    width: 100%;
    display: flex;
    gap: 4%;
    flex-wrap: wrap;
}

.inner_slider .inner_left {
    width: 60%;
}

.inner_slider .inner_left h2 {
    font-weight: 700;
    font-size: 36px;
    padding-top: 100px;
    padding-bottom: 20px;
}

.inner_slider .inner_left p {
    font-size: 20px;
}

.inner_slider .inner_right {
    width: 36%;
}

.explore_courses {
    padding-top: 80px;
}

.explore_courses h2 {
    font-size: 32px;
    font-weight: 500;
}

.explore_courses p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 35px;
}

.explore_courses ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    margin: 0 auto;
}

.explore_courses ul li {
    width: 30.5%;
    padding: 10px 10px 16px 10px;
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    margin-bottom: 35px;
    border-radius: 8px;
}

.explore_courses ul li span {
    display: block;
}

.explore_courses ul li h4 {
    font-size: 18px;
}

.explore_courses ul li p {
    font-size: 16px;
}

.explore_courses ul li span {
    color: #7BC12E;
    padding: 10px 0 5px;
}

.explore_courses ul li img {
    width: 100%;
}

.explore_courses ul li a.btn_launch {
    font-size: 15px;
    background-color: #7BC12E;
    display: inline-block;
    padding: 4px 25px;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 600;
}

.explore_courses ul li a.btn_launch:hover {
    background-color: #6EB024;
    font-weight: 500;
}

.explore_courses ul li .height_courses {
    min-height: 112px;
}

.explore_courses ul li .label {
    background-color: #FFCF3D;
    padding: 6px 19px;
    font-size: 12px;
    border-radius: 8px 0 8px 0;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.learn_video {
    width: 100%;
    padding: 50px 0 0;
}

.learn_video h2 {
    font-size: 32px;
    font-weight: 500;
}

.learn_video p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 35px;
}

.learn_video ul {
    display: flex;
    gap: 2%;
}

.learn_video ul li {
    width: 32%;
    text-align: center;
    background: radial-gradient(73.18% 63.16% at 50% 50%, #4CBCBF 28.17%, #096767 100%);
    border-radius: 4px;
    padding: 30px;
    min-height: 228px;
}

.learn_video ul li h3 {
    font-size: 22px;
    color: #ffffff;
    padding-bottom: 20px;
    font-weight: 600;
    margin: 0;
}

.learn_video ul li h3 span {
    display: block;
}

.learn_video ul li a {
    text-decoration: none;
    color: #ffffff;
}

.learn_video ul li a span {
    display: none;
    font-size: 15px;
    font-weight: 600;
}

.learn_video ul li .play_icon {
    padding-bottom: 10px;
}

.learn_video ul li:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
}

.learn_video ul li:hover a span {
    display: block;
}

.complementy_form {
    margin-top: 80px;
}

/* Search */
.hitWrapper {
    position: absolute;
    left: 0;
    top: 34px;
    padding: 10px;
    overflow-x: scroll;
    max-height: 455px;
}

.hitWrapper::-webkit-scrollbar {
    width: 1em;
}


.hitWrapper::-webkit-scrollbar-thumb {
    background-color: #6EB024;
    outline: 1px solid #6EB024;
}

.hideResults {
    display: none;
}

/* .search_overlap {
    position: relative;
    min-width: 452px;
    z-index: 30;
} */

.search_overlap {
    position: absolute;
    top: 19px;
    right: 250px;
    min-width: 452px;
    z-index: 30;
}

.search_bg::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(153, 156, 144, 0.3);
}

.close_icon {
    color: rgba(32, 32, 34, 1);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    padding: 2px 7px;
    cursor: pointer;
}


/* Content Page */
.content_page {
    font-size: 20px;
    line-height: 1.7;
    font-weight: 400;
    padding-top: 100px;
}

.content_page h1 {
    font-size: 36px;
    font-weight: 700;
    padding-top: 40px;
}

.content_page h1 span {
    display: block;
}

.content_page h2 {
    font-size: 32px;
    font-weight: 500;
    padding-top: 40px;
    color: rgba(123, 193, 46, 1);
}

.content_page h3 {
    font-size: 24px;
    font-weight: 500;
    padding-top: 40px;
}

.content_page ul {
    padding-left: 20px;
}

.content_page ul li {
    list-style-type: disc;
    padding-top: 2px;
}

.content_page p,
.content_page ul {
    padding-top: 10px;
}

.content_page .table_content h3 {
    padding-top: 40px;
    font-weight: 500;
    font-size: 32px;
}

.content_page .table_content ul {
    padding-left: 0;
}

.content_page .table_content ul li {
    list-style-type: none;
}

.content_page .table_content ul li a {
    text-decoration: underline;
    cursor: pointer;
}

.content_page .table_content ul li a:hover {
    color: rgba(110, 176, 36, 1);
    text-decoration: underline;
}

.content_page p a {
    text-decoration: underline;
    cursor: pointer;
    color: #000;
}

.content_page p a:hover {
    color: rgba(110, 176, 36, 1);
    text-decoration: underline;
}

/* Discussion */
.discussion_slider {
    width: 100%;
    display: flex;
    gap: 60px;


    padding: 16px 89px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 431px;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.discussion_slider .discussion_left {
    flex: 1;
}

.discussion_slider .discussion_left h2 {
    font-weight: 700;
    font-size: 36px;
    /* padding-top: 100px; */
    padding-bottom: 20px;
}

.discussion_slider .discussion_left p {
    font-size: 20px;
    margin: 0;
}

.discussion_slider .discussion_right {
    flex: 1;
    text-align: right;
}

.edgeenginegroup_logo {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5%;
    padding-top: 100px;
}

.edgeenginegroup_logo .edgeenginegroup_block {
    width: 100%;
}

.edgeenginegroup_logo .right_edge {
    width: 33%;
}

.edgeenginegroup_logo h3 {
    font-size: 24px;
    font-weight: 600;
}

.edgeenginegroup_logo h3 span {
    font-size: 20px;
}

.edgeenginegroup_logo h4 {
    font-size: 20px;
    font-weight: 600;
    color: #7BC12E;
    padding-bottom: 10px;
}

.edgeenginegroup_logo ul {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding-bottom: 26px;
}

.edgeenginegroup_logo ul li {
    width: 31.33%;
    margin-bottom: 16px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0 rgba(208, 208, 208, 0.5);
    min-height: 79px;
    padding: 3px;
    border: #ffffff 1px solid;
    max-width: 295px;
}

.edgeenginegroup_logo ul li:hover {
    border-color: rgba(123, 193, 46, 1);
}

.edgeenginegroup_logo ul li .discussion_ic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.edgeenginegroup_logo ul li .discussion_ic .discssion_ic_left {
    width: 30%;
}

.edgeenginegroup_logo ul li .discussion_ic .discussion_ic_right {
    width: 68%;
    padding: 21px 13px 21px 0;
}

.edgeenginegroup_logo ul li .discussion_ic .discussion_ic_right h3 {
    font-size: 15px;
    font-weight: 700;
}

.edgeenginegroup_logo ul li .discussion_ic .discussion_ic_right h3:hover {
    color: #6EB024;
}

.edgeenginegroup_logo ul li .discussion_ic .discussion_ic_right p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(41, 41, 41, 1);
    margin: 0;
}

.edgeenginegroup_logo ul li a {
    text-decoration: none;
    color: rgba(41, 41, 41, 1);
}

.edgeenginegroup_logo ul li a:hover .discussion_ic .discussion_ic_right h3 {
    text-decoration: underline;
}

.edgeenginegroup_logo ul li .discussion_ic .discussion_ic_right p img {
    float: right;
}

.edgeenginegroup_logo .right_edge ul li {
    width: 100%;
}

.slider_btm .swiper-slide {
    text-align: center;
}

.loading {
    position: absolute;
    left: 50%;
    margin-left: -24px;
    top: 50%;
    margin-top: -24px;
}

.submitted-message {
    color: #ffffff;
}


@media (min-width:767px) {

    /* hamburger menu visibility  */
    .show_on_mobile {
        display: none;
    }

    .hide_on_mobile {
        display: block;
    }
}

@media (min-width:961px) and (max-width:1200px) {
    .search_overlap {
        position: absolute;
        top: 19px;
        right: 250px;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .edgeengine_logo {
        gap: 40px;
    }

    .explore_courses ul li .height_courses {
        min-height: 130px;
    }



    /* home page */

    .home_wrapper {
        flex-wrap: nowrap;
    }

    .home_banner {
        flex-wrap: nowrap;
        flex-direction: column;
        row-gap: 36px;
    }

    .home_banner_copy_box {
        width: 100%;
        align-items: center;
    }

    .home_banner_copy_box a.button {
        width: 100%;
        max-width: 461px;
    }

    /* download page */

    .download_wrapper {
        padding-bottom: 16px;
        align-content: center;
        align-items: center;
    }

    .download_banner {
        row-gap: 48px;
        justify-content: center;
    }


    /* courses page */

    .courses_wrapper {
        padding-bottom: 16px;
        align-content: center;
        align-items: center;
    }

    .courses_banner {
        row-gap: 8px;
        align-self: stretch;
        justify-content: center;
    }

}

@media (max-width:960px) {

    .show_on_mobile {
        display: block;
    }

    .hide_on_mobile {
        display: none;
    }


    /* home page */

    .journey_area h2 {
        font-size: 24px;
    }

    .journey_area p {
        font-size: 18px;
    }

    .home_slider {
        padding-top: 0;
        padding-bottom: 0;
    }

    .home_slider .slider_left,
    .home_slider .slide_right {
        width: 100%;
    }

    .journey_area .journey_list,
    .edge_btns,
    .home_slider {
        flex-direction: column;
    }

    .journey_block .journey_tp img {
        width: 100%;
    }

    .logo_slider {
        padding: 40px;
    }

    .footer_section {
        flex-direction: column-reverse;
    }

    .copyright_section {
        flex-direction: column;
        text-align: center;
    }

    .copyright {
        padding: 0 0 10px 0;
    }

    .footer_bg {
        padding: 30px 10px;
    }

    .user_menu>a {
        display: none;
    }

    .navigation {
        display: none;
    }

    .user_menu a.search_icon {
        font-size: 24px;
        margin-top: -5px;
    }

    .home_slider .slider_left .slide_btns {
        display: block;
        padding-top: 20px;
    }

    .home_slider .slider_left .slide_btns span {
        display: block;
        padding: 10px 0;
    }

    .bottom_links {
        font-size: 14px;
    }

    .journey_area .journey_list {
        gap: 0px;
    }

    .bottom_links_mobile {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 20px;
    }

    .footer_content {
        padding: 30px 0;
        border-top: #fff 1px solid;
        border-bottom: #ccc 1px solid;
    }

    .newsletter {
        border-bottom: none;
        padding: 0;
    }

    .social_links {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .social_links ul {
        justify-content: center;
    }

    .searh_outer {
        padding: 0;
    }

    .bottom_links_mobile h6 {
        width: 100%;
    }

    .bottom_links h6 {
        padding: 12px 0 5px;
    }

    .bottom_links h6.top_btmgap {
        padding-top: 12px;
    }

    .user_menu {
        flex-direction: row-reverse;
    }

    .user_menu>span {
        margin-left: 20px;
        margin-right: 10px;
    }

    .mobile_navigation {
        position: fixed;
        z-index: 99;
        width: 100%;
        overflow-y: scroll;
        height: 100%;
        background-color: #ffffff;
        left: 0;
        top: 0;
        padding: 20px;
    }

    .home_slider .slider_left .slide_btns a.get_started_btn {
        display: inline-block;
        font-size: 16px;
        padding: 12px 38px;
        border-radius: 4px;
    }

    .logo_mobile {
        text-align: center;
        padding: 20px 0;
    }

    .back_icon {
        color: #7BC12E;
        font-size: 22px;
    }

    .journey_area .journey_list .journey_block {
        flex-direction: column-reverse;
    }

    .journey_area .journey_list .journey_block .journey_tp,
    .journey_area .journey_list .journey_block .joruney_outer {
        width: 100%;
    }

    .journey_block .joruney_outer .journey_count span {
        font-size: 48px;
        padding-right: 10px;
    }

    .journey_block .joruney_outer h2 {
        font-size: 22px;
        padding-top: 19px;
        line-height: 1;
    }

    .journey_block .joruney_outer h3 {
        clear: both;
        font-size: 16px;
    }

    .journey_block .joruney_outer h4 {
        font-size: 17px;
    }

    .journey_block .joruney_outer ul li strong {
        font-size: 16px;
    }

    .journey_block .joruney_outer ul li svg {
        font-size: 21px;
    }

    .journey_block .joruney_outer {
        padding-bottom: 0;
    }

    .journey_area .journey_list .journey_block {
        gap: 0;
    }

    .journey_area .journey_list .journey_block:nth-child(2n) {
        flex-direction: column-reverse;
    }

    .bottom_link_two {
        width: 50%;
    }


    /* mobile menu */
    .mobile_menu {
        font-size: 20px;
        padding: 30px;
        font-weight: 500;
    }

    .mobile_menu>ul>li {
        clear: both;
    }

    .mobile_menu>ul>li>a {
        display: inline-block;
        padding: 7px 0;
        color: #393939;
        text-decoration: none;
    }

    .mobile_menu>ul>li>a.active {
        color: #6EB024;
    }

    .mobile_menu>ul>li>ul {
        background-color: #F5F9E8;
        margin-left: 10%;
        width: 90%;
        float: left;
    }

    .mobile_menu>ul>li>ul>li:first-child>a,
    .nav_menu>ul>li>ul>li>ul>li:first-child>a,
    .mobile_menu>ul>li>ul>li>ul>li>ul>li:first-child>a,
    .mobile_menu>ul>li>ul>li>ul>li>ul>li>ul>li:first-child>a {
        border-top: 0
    }

    .mobile_menu>ul>li>ul>li {
        width: 100%;
        float: left;
        position: relative;
        list-style-type: none;
    }

    .mobile_menu>ul>li>ul>li>ul>li>ul>li>a,
    .nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li>a {
        font-size: 13px;
        font-weight: 400;
        text-decoration: none
    }

    .mobile_menu>ul>li>ul>li>a {
        color: #626262;
        font-size: 18px;
        font-weight: 400;
        padding: 10px 40px 10px 10px;
        display: block;
        text-decoration: none;
    }

    .mobile_menu>ul>li>ul>li:last-child>a {
        border-radius: 0 0 4px 4px;
    }

    .mobile_menu>ul>li>ul>li>a:hover {
        color: #ffffff;
        background-color: rgba(110, 176, 36, 1);
    }

    .mobile_menu>ul>li>ul>li>ul {
        display: none;
        position: absolute;
        width: 280px;
        background-color: #fff;
        left: 280px;
        top: 0px;
        z-index: 99900;
        margin: 0;
    }

    .mobile_menu>ul>li>ul>li:hover>ul {
        display: block;
    }

    .mobile_btm_menu {
        margin-left: -20px;
        margin-right: -20px;
        padding-top: 30px;
        clear: both;
    }

    .mobile_btm_menu ul li a {
        display: block;
        padding: 20px 30px;
        text-decoration: none;
        font-size: 20px;
        color: #7BC12E;
    }

    .mobile_btm_menu ul li:nth-child(2) a {
        background-color: #7BC12E;
        color: #ffffff;
        margin-bottom: 30px;
    }

    .mobile_btm_menu ul li:first-child a {
        background-color: #000000;
        color: #ffffff;
    }

    .mobile_btm_menu ul li+li+li a {
        padding: 10px 30px;
    }

    /* download */
    .edgeengine_logo {
        flex-direction: column;
        gap: 0;
    }

    .download_slider {
        flex-direction: column;
        gap: 0;
    }

    .download_slider .download_left h2 {
        padding: 40px 0 10px;
    }

    .edgeengine_logo {
        padding-top: 10px;
    }

    /* complementry page */
    .block_overview {
        flex-direction: column;
        gap: 0;
    }

    .topic_header {
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .topic_header .topic_right {
        display: block;
        text-align: left;
    }

    .topic_header .topic_right ul {
        display: flex;
    }

    .topic_header .topic_right .topic_level {
        border-radius: 0 4px 4px 0;
        padding: 3px 16px;
        display: inline-block;
    }

    .topic_header .topic_right ul {
        max-width: 100%;
        line-height: 1;
        display: inline-block;
        padding-left: 10px;
    }

    .topic_header .topic_right ul li {
        padding: 0 7px;
        border-left: #ffffff 1px solid;
        display: inline-block;
    }

    .topic_header .topic_right ul li:first-child {
        border: none;
    }

    .topic_header .topic_right .topic_level {
        font-size: 16px;
    }

    .explore_courses ul {
        flex-direction: column;
    }

    .explore_courses ul li,
    .learn_video ul li {
        width: 100%;
    }

    .learn_video ul {
        flex-direction: column;
        gap: 10px;
    }

    .inner_slider {
        flex-direction: column;
    }

    .inner_slider .inner_left,
    .inner_slider .inner_right {
        width: 100%;
    }

    .track_area {
        padding: 20px;
        flex-direction: column;
    }

    .track_area .track_right a {
        padding: 10px 20px;
    }

    .track_area .track_right {
        padding: 10px;
    }

    .contact_area {
        padding: 20px;
    }

    /* commnunity */
    .discussion_slider {
        flex-direction: column;
    }

    .discussion_slider .discussion_left h2,
    .inner_slider .inner_left h2,
    .support_slider .support_left h2 {
        padding-top: 60px;
        margin: 0;
    }

    .edgeenginegroup_logo {
        flex-direction: column;
    }

    .edgeenginegroup_logo .edgeenginegroup_block,
    .edgeenginegroup_logo .right_edge {
        width: 100%;
    }

    .edgeenginegroup_logo ul {
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
    }

    .edgeenginegroup_logo ul li {
        width: 100%;
        max-width: inherit;
        border-radius: 12px;
        box-shadow: 2px 2px 5px 0 rgba(208, 208, 208, 0.5);
    }

    .topic_header .topic_left {
        padding: 20px;
    }


    /* support page */
    .support_bottom_block {
        flex-direction: column;
    }

    .support_area {
        padding: 20px;
    }

    .support_slider {
        flex-direction: column;
    }

    .support_bottom_block .support_btm_area .support_btn {
        max-width: 100%;
    }

    .support_bottom_block .support_btm_area .support_img {
        min-height: 100%;
    }

    /* hec community */
    .hec_slider {
        flex-direction: column;
    }

    .hec_support {
        flex-direction: column;
        gap: 0;
    }

    .edgeengine_logo ul li .download_ic .icon_hover a {
        font-size: 15px;
    }

    .edgeengine_logo ul li {
        min-height: 170px;
    }

    .table_download {
        height: 170px;
    }

    .hec_support .hec_explore a {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    /* search */
    .search_overlap {
        min-width: inherit;
        position: absolute;
        top: 53px;
        left: 10px;
        right: 10px;
    }

    .edgeengine_logo ul {
        gap: 4%;
    }

    .edgeengine_logo ul li {
        width: 48%;
    }


}


@media (max-width:768px) {

    .show_on_mobile {
        display: block;
    }

    .hide_on_mobile {
        display: none;
    }

    /* home page (tablet) */

    .home_wrapper {
        padding-bottom: 16px;
        align-content: center;
        align-items: center;
    }

    .ai_banner {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        display: inline-flex;
        flex-direction: column;
        width: calc(101vw);
        height: 244px;
        left: 0;
        position: relative;
        align-items: center;
        align-content: center;
        /* background: #331772; */
        background: center no-repeat url("../images/579x244-02-1x.png"), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background: -webkit-image-set(
            url("../images/579x244-02-1x.png") 1x,
            url("../images/579x244-02-2x.png") 2x,
            url("../images/579x244-02-3x.png") 3x,
            url("../images/579x244-02-4x.png") 4x
                ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background: image-set(
            "../images/579x244-02-1x.png" 1x,
            "../images/579x244-02-2x.png" 2x,
            "../images/579x244-02-3x.png" 3x,
            "../images/579x244-02-4x.png" 4x
                ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .ai_link {
        width: calc(95vw);
        text-align: right;
        padding-top: 20px;
    }

    .ai_buttons {
        width: 100%;
        height: 190px;
        justify-content: center;
        align-items: flex-end;
        gap: 20.72px;
        display: inline-flex;
    }

    .home_banner {
        row-gap: 8px;
        justify-content: center;
    }

    .home_banner_copy_box a.button {
        width: 100%;
    }

    .home_banner_asset {
        width: 220px;
        height: 220px;
        flex-shrink: 0;
    }

    .home_cards {
        /* flex-direction: column; */

        align-items: flex-start;
        width: 100%;
    }

    .home_card1,
    .home_card2,
    .home_card3 {
        flex-direction: column;
        padding: 36px 16px;
    }



    .home_card1_copy_box,
    .home_card2_copy_box,
    .home_card3_copy_box {
        height: 240px;
        width: 186px;
    }

    .home_card3_copy_box a.button {
        width: unset;
        /* margin-left: -10px; */

    }

    /* download page (tablet) */

    .download_wrapper {
        padding-bottom: 16px;
        align-content: center;
        align-items: center;
    }

    .download_banner {
        row-gap: 48px;
        justify-content: center;
    }

    .download_banner_asset {
        width: 226.231px;
        height: 207.818px;
        flex-shrink: 0;
    }

    /* courses page (tablet) */

    .courses_wrapper {
        padding-bottom: 16px;
        align-content: center;
        align-items: center;
    }

    .courses_banner {
        row-gap: 8px;
        align-self: stretch;
        justify-content: center;
    }

    .courses_banner_copy_box {
        align-items: center;
        text-align: center;
    }

}


@media (max-width:479px) {
    .tab_topic ul li {
        padding: 10px 12px;
        margin: 0;
    }
}

@media screen and (max-width:450px) {
    .show_on_mobile {
        display: block;
    }

    .hide_on_mobile {
        display: none;
    }

    /* home page (mobile) */

    .home_wrapper {
        /* width: 353px; */
    }

    .ai_banner {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        display: inline-flex;
        flex-direction: column;
        width: calc(101vw);
        height: 236px;
        left: 0;
        position: relative;
        align-items: center;
        align-content: center;
        /* background: #331772; */
        /* background-image: url("../images/ai-366X200.png") ; */
        background: center no-repeat url("../images/366x236-02-1x.png"), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background: -webkit-image-set(
            url("../images/366x236-02-1x.png") 1x,
            url("../images/366x236-02-2x.png") 2x,
            url("../images/366x236-02-3x.png") 3x,
            url("../images/366x236-02-4x.png") 4x
                ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background: image-set(
            "../images/366x236-02-1x.png" 1x,
            "../images/366x236-02-2x.png" 2x,
            "../images/366x236-02-3x.png" 3x,
            "../images/366x236-02-4x.png" 4x
                ), linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgb(7, 12, 32) 89%, rgb(6, 12, 30) 100%);
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .ai_link {
        width: 330px;
        text-align: right;
        padding-top: 12px;
    }

    .ai_buttons {
        width: 100%;
        height: 190px;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        display: inline-flex;
        flex-direction: column;
    }

    .home_banner {
        text-align: center;
        padding: 16px 16px;
    }

    .home_banner_copy_box {
        width: 100%;
        align-items: center;
    }

    .home_banner_copy_box h1 {
        font-size: 28px;
    }

    .home_cards {
        width: 353px;
        padding: 16px 16px;
        align-items: center;
        align-content: center;
    }

    .home_card1_copy_box,
    .home_card2_copy_box,
    .home_card3_copy_box {
        height: auto;
        width: auto;
    }

    .home_card3_copy_box a.button {
        width: auto;

    }

    /* download page (mobile) */

    .download_wrapper {
        width: 353px;
    }

    .download_banner {
        max-width: 353px;
    }

    .download_banner_copy_box {
        width: 361px;
        /* padding: 32px 16px; */
        flex-direction: column;
        align-items: center;
        align-content: space-between;
        gap: 32px;
    }

    .download_banner_copy_box h1 {
        font-size: 28px;
    }
}

/* loader */

.loading_outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading_text {
    text-align: center;
    padding-top: 30px;
    font-size: 32px;
    font-weight: 700;
}

.loader {
    display: flex;
    align-items: center;


}

/* Creating the dots */
.loader span {
    height: 25px;
    width: 25px;
    margin-right: 30px;
    border-radius: 50%;
    background-color: green;
    animation: loading 1s linear infinite;
}

/* Creating the loading animation*/
@keyframes loading {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(15px);
    }

    50% {
        transform: translateX(-15px);
    }

    100% {
        transform: translateX(0);
    }

}

.loader span:nth-child(1) {
    animation-delay: 0.1s;
    background-color: #E6352B;
}

.loader span:nth-child(2) {
    animation-delay: 0.2s;
    background-color: #F3971D;
}

.loader span:nth-child(3) {
    animation-delay: 0.3s;
    background-color: #FCC30D;
}

.loader span:nth-child(4) {
    animation-delay: 0.4s;
    background-color: #ABC837;
}

.loader span:nth-child(5) {
    animation-delay: 0.5s;
    background-color: #41ABB5;
}



/* error page */

.error_page {
    width: 100%;
    font-size: 20px;
}

.error_page .error_img svg {
    max-height: 300px;
}

.error_page h2 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    padding-top: 50px;
}

.error_page p {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    font-size: 20px;
}

.error_content {
    display: flex;
    padding-top: 80px;
}

.error_content a {
    text-decoration: underline;
    color: #000000;
}

.error_content a:hover {
    color: rgba(110, 176, 36, 1);
    text-decoration: underline;
}

.error_content .intresting_link {
    flex: 1;
}

.error_content .intresting_link h3 {
    font-size: 20px;
    font-weight: 700;
}

.error_content .intresting_link ul li {
    padding: 5px 0;
}

.error_content .ready_things {
    flex: 1;
}

.error_content .ready_things h3 {
    font-size: 20px;
    font-weight: 700;
}

.error_content .ready_things p {
    text-align: left;
    padding: 5px 0;
}

.error_img {
    padding: 50px 0;
    text-align: center;
    position: relative;
}

.error_img span {
    display: block;
    position: relative;
}

.error_img h1 {
    position: absolute;
    top: 104px;
    left: 0;
    text-align: center;
    font-size: 80px;
    font-weight: 700;
    width: 100%;
}

@media (max-width:767px) {
    .error_content {
        flex-direction: column;
    }

    .error_page .error_img svg {
        width: 100%;
    }

    .error_content .ready_things {
        padding-top: 40px;
    }

    .journey_block .joruney_outer {
        top: 0;
    }

    .journey_area .journey_list .journey_block {
        padding-top: 0;
    }
}


/* react cookie notice  */
.react-cookie-notice-container {
    background: #222222;
    color: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.5rem;
}

.react-cookie-notice-container .react-cookie-notice-content {
    max-width: 900px;
    padding: 1rem 2rem;
}

.react-cookie-notice-container .react-cookie-notice-content ul {
    /* background: #ffffff;
    color: #000000; */
    list-style-type: none;
    margin: 0 0 1rem 0;
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .react-cookie-notice-container .react-cookie-notice-content ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.react-cookie-notice-container .react-cookie-notice-content ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
}

.react-cookie-notice-container .react-cookie-notice-content ul li strong {
    margin-left: 0.5rem;
}

.react-cookie-notice-container .react-cookie-notice-content ul li p {
    margin-top: 0;
}

.react-cookie-notice-container .react-cookie-notice-content .react-cookie-notice-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.react-cookie-notice-container .react-cookie-notice-content .react-cookie-notice-buttons .react-cookie-notice-button-primary {
    /* background: #5fdafb;
        color: #000;
        text-decoration: none;
        padding: 1rem;
        text-align: center;
        border: 0;
        margin-left: auto; */

    background-color: #ffffff;
    border: #000000 1px solid;
    color: #000000;
    padding: 7px 30px;
    font-size: 14px;
    margin-bottom: 7px;
    border-radius: 4px;
}

.react-cookie-notice-container .react-cookie-notice-content .react-cookie-notice-buttons .react-cookie-notice-button-primary:hover {
    background-color: #6EB024;
    color: #ffffff;
    border-color: #6EB024;
}

.react-cookie-notice-container .react-cookie-notice-content .react-cookie-notice-buttons .react-cookie-notice-button-secondary {
    background: none;
    color: #ffffff;
    padding: 0;
    text-decoration: underline;
    text-align: left;
    border: 0;
}